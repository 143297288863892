
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController,
} from "@ionic/vue";
import axios from "axios";

export default defineComponent({
  name: "Register",
  data() {
    return {
      otp: null,
      btnLoading: false,
    };
  },
  methods: {
    async postOTP() {
      const idUser = localStorage.getItem("id_user_regis");
      const formData = new FormData();
      idUser && formData.append("id_user", idUser);
      this.otp && formData.append("otp", this.otp);

      this.btnLoading = true;

      await axios
        .post("otp-check", formData)
        .then((data) => {
          localStorage.removeItem('id_user_regis')
          this.btnLoading = false;
          this.$router.replace('/main/home')
        })
        .catch(async ({ response }) => {
          this.btnLoading = false;
          this.otp = null
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000
          });
          return toast.present();
        });
    },
  },
  components: { IonPage, IonContent, IonItem, IonLabel, IonInput, IonButton },
});
